import React, { useEffect, useState } from "react";
import { client } from "../../client";

function DirectorySubList({ ecid }) {
  const [ecommtSub, setEcommtSub] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[dECommittee._ref == $ecid ] | order(name asc) {
          _id,
          name,
          cname,
          pname,
          pphone,
          sphone,
          code,
          email, 
          _rev,       
    }`,
        { ecid }
      )
      .then((data) => setEcommtSub(data))
      .catch(console.error);
  }, [ecid]);
  if (!ecommtSub) {
    return <h2></h2>;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          {ecommtSub &&
            ecommtSub.map((item, index) => (
              <div className="col-lg-6" key={index}>
                <div className="td-sidebar subScl">
                  <div className="widget widget_feature">
                    <h2 className="widget-title">{item.name}</h2>
                    <ul>
                      <li>
                        <span>
                          <i className="fa fa-user" />
                          CORRESPONDENT NAME :
                        </span>{" "}
                        {item.cname}
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-user" />
                          PRINCIPAL NAME :
                        </span>{" "}
                        {item.pname}
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-phone" />
                          PRINCIPAL PHONE NUMBER :
                        </span>{" "}
                        {item.pphone}
                      </li>
                      <li>
                        <span>
                          {" "}
                          <i className="fa fa-mobile" />
                          SCHOOL PHONE NUMBER :
                        </span>{" "}
                        {item.sphone}
                      </li>
                      <li>
                        <span>
                          {" "}
                          <i className="fa fa-envelope" />
                          SCHOOL EMAIL ID :
                        </span>{" "}
                        {item.email}
                      </li>
                      <li>
                        <span>
                          {" "}
                          <i className="fa fa-barcode" />
                          UDISE CODE :
                        </span>{" "}
                        {item.code}
                      </li>
                    </ul>
                    {/* <div className="price-wrap text-center">
                  <h5>
                    Price:<span>$54.00</span>
                  </h5>
                  <a
                    className="btn btn-base btn-radius"
                    to="/course-details"
                  >
                    ENROLL COURSE
                  </a>
                </div> */}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default DirectorySubList;
