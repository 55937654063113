import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Intro extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="intro-area intro-area--top">
        <div className="container">
          <div className="intro-area-inner intro-home-1 bg-black">
            <div className="row no-gutters">
              <div className="col-lg-12 align-self-center">
                <ul className="row no-gutters">
                  <li className="col-md-3">
                    <div className="single-intro-inner style-white text-center">
                      <div className="thumb">
                        <img
                          src={publicUrl + "assets/img/intro/1.png"}
                          alt="img"
                        />
                      </div>
                      <div className="details">
                        <h5>Critical Thinking</h5>
                        <p>Encouraging students to think critically</p>
                      </div>
                    </div>
                  </li>
                  <li className="col-md-3">
                    <div className="single-intro-inner style-white text-center">
                      <div className="thumb">
                        <img
                          src={publicUrl + "assets/img/intro/5.png"}
                          alt="img"
                        />
                      </div>
                      <div className="details">
                        <h5>Creativity</h5>
                        <p>Fostering creativity and innovation</p>
                      </div>
                    </div>
                  </li>
                  <li className="col-md-3">
                    <div className="single-intro-inner style-white text-center">
                      <div className="thumb">
                        <img
                          src={publicUrl + "assets/img/intro/3.png"}
                          alt="img"
                        />
                      </div>
                      <div className="details">
                        <h5>Adaptability</h5>
                        <p>Teaching students to adapt to change</p>
                      </div>
                    </div>
                  </li>
                  <li className="col-md-3">
                    <div className="single-intro-inner style-white text-center">
                      <div className="thumb">
                        <img
                          src={publicUrl + "assets/img/intro/2.png"}
                          alt="img"
                        />
                      </div>
                      <div className="details">
                        <h5>Knowledge Acquisition</h5>
                        <p>Providing a solid foundation of knowledge</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
