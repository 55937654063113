import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Team from "./section-components/team-v3";
import Footer from "./global-components/footer";

const OfficeBearers = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Governing Body" />

      <Team />

      <Footer />
    </div>
  );
};

export default OfficeBearers;
