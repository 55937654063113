import React, { Component, useEffect, useState } from "react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { client } from "../../client";

const TeamV3 = () => {
  const [offBear, setOffBear] = useState(null);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "officeBearers"] | order(publishedAt asc){
        title,
        desi,
        mainImage{
             asset->{
                  _id,
                  url
                }
              },
    }`
      )
      .then((data) => setOffBear(data))
      .catch(console.error);
  }, []);
  if (!offBear) {
    return <h2></h2>;
  }
  return (
    <div className="team-area pd-top-120 pd-bottom-90 go-top">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7">
            <div className="section-title text-center">
              <h2 className="sub-title double-line lineB">Office Bearers</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {offBear &&
            offBear.map((item, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="single-team-inner">
                  <div className="thumb">
                    <img src={item.mainImage.asset.url} alt="img" />
                  </div>
                  <div className="details">
                    <h6>
                      <a href="javascript:void(0)">{item.title}</a>
                    </h6>
                    <span>{item.desi} </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TeamV3;
