import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { client } from "../../client";

const Gallery = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [gallery, setGallery] = useState(null);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "gallery"] | order(publishedAt desc){
        title,
        slug,
        mainImage{
             asset->{
                  _id,
                  url
                }
              },
    }`
      )
      .then((data) => setGallery(data))
      .catch(console.error);
  }, []);
  if (!gallery) {
    return <h2></h2>;
  }

  return (
    <div className="team-area pd-top-40 pd-bottom-90 go-top">
      <div className="container">
        <div className="row justify-content-center col-size-full">
          {gallery &&
            gallery.map((item, index) => (
              <div className="col-Size" key={index}>
                <div className="single-gallery-inner">
                  <div className="thumb">
                    <img src={item.mainImage.asset.url} alt="img" />
                  </div>
                  <div className="details">
                    <h4>
                      <Link to={`/gallery-detail/` + item.slug.current}>
                        {item.title}
                      </Link>
                    </h4>
                    <span>
                      <Link to={`/gallery-detail/` + item.slug.current}>
                        Read More
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          {/* 
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/2.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/3.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>

          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/4.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/5.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/6.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>

          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/7.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/8.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/9.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>

          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/10.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/11.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/12.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>

          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/13.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/14.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/15.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>

          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/16.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/17.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/18.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>

          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/1.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/20.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/21.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>

          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/22.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/23.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/24.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>

          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/25.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-Size">
            <div className="single-gallery-inner">
              <div className="thumb">
                <img src={publicUrl + "assets/img/gallery/26.jpg"} alt="img" />
              </div>
              <div className="details">
                <span></span>
                <h4>
                  <Link to="#"></Link>
                </h4>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
