import React from "react";

function TimeLine() {
  return (
    <>
      <div
        className="team-area pd-top-110 go-top"
        style={{ backgroundImage: "url(assets/img/bg/pricing-bg.png)" }}
      >
        <div className="container-fluid pl-4 pr-4">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7">
              <div className="section-title text-center">
                <h6 className="sub-title double-line">About ACMS</h6>
                <h2 className="title">A Brief History of ACMS</h2>
              </div>
            </div>
          </div>
          <div className="timelineTime">
            <div className="containerTime leftTime b-animate-2">
              <div className="contentTime widget_feature ">
                <h2>2010</h2>
                <p>
                  In 2010, Academic council of Matriculation Schools was
                  initiated by the then Director of Matriculation Schools Thiru
                  K.Devarajan. In Coimbatore, the then Inspector of
                  Matriculation Schools Thiru Elango under the stewardship of
                  Chief Educational Officer Tmt.Anandhi started ACMS in 2010.
                  Senior Principals of Matriculation Schools joined together and
                  selected its office-bearers.
                </p>

                <ul>
                  <li>
                    <i className="fa fa-user" />
                    <span>Convenor :</span> Rev.Fr.PaulRaj, Principal, Carmel
                    Garden MHSS
                  </li>
                  <li>
                    <i className="fa fa-user" />
                    <span>Secretary :</span> Mr.Annadurai, Principal, RVS MHSS
                    (And after his Transfer) Mr. AntonyRaj, Principal, Suguna
                    RIP V MHSS
                  </li>
                  <li>
                    <i className="fa fa-user" />
                    <span>Treasurer :</span> Mr.P.V.Antony, Principal, Trinity
                    MHSS and with the Executive Members
                    <ul className="underLI">
                      <li>Mrs.Prasanna, Principal, GKD</li>
                      <li>Mr.Pratap.F, Principal, BVN ,Pollachi</li>
                    </ul>
                  </li>
                </ul>
                <p>
                  In 2010, we the Principals of Matriculation Schools could act
                  and support well for the success of Semmozhi Manadu by the
                  then Chief Minister Thiru.M.Karunanidhi. We started to honour
                  all the principals and students who have produced Centum
                  Results in Board Exams.
                </p>
              </div>
            </div>
            <div className="containerTime rightTime b-animate-thumb">
              <div className="contentTime widget_feature">
                <h2>2011</h2>
                <p>
                  In 2011, the new office bearers have taken the
                  responsibilities with a view of systematic administration of
                  Matric Schools in Coimbatore under the CEO and IMS.
                </p>
                <ul>
                  <li>
                    <i className="fa fa-user" />
                    <span>Convenor :</span> Mr.P.V.Antony, Principal, Suguna RIP
                    V MHSS
                  </li>
                  <li>
                    <i className="fa fa-user" />
                    <span>Joint Convenor :</span> Mrs.Geetha Gopinath,
                    Principal, SBIOA MHSS
                  </li>
                  <li>
                    <i className="fa fa-user" />
                    <span>Secretary :</span> Mr.Hajah Sheriff, Principal, CMS
                    MHSS
                  </li>
                  <li>
                    <i className="fa fa-user" />
                    <span>Joint Secretary :</span> : Mrs.Poongothai. Principal,
                    GRG MHSS
                  </li>
                  <li>
                    <i className="fa fa-user" />
                    <span>Treasurer :</span> : Rev.Fr.P Arokia Thadayus,
                    Principal, St.Joseph MHSS
                  </li>
                  <li>
                    <i className="fa fa-user" />
                    <span>Auditing Committee :</span>
                    <ul className="underLI">
                      <li>Mrs.Prasanna Radhakrishnan, Principal, GKD MHSS</li>
                      <li>
                        Mrs.Vasanthi Balraj, Principal, Konguvellalar MHSS
                      </li>
                      <li> Mrs.Sagunthalamani.R, Principal, Nachiar MHSS</li>
                    </ul>
                  </li>
                </ul>
                <p>
                  We co-ordinated all the 268 Matric Schools and divided area
                  wise into 22 co-ordinating Schools. This council acts as
                  liaison between the Department of Education and the
                  Matriculation Schools. Periodically, the Co-ordinators
                  Meetings are held to enhance all the activities of the
                  committee.
                </p>
              </div>
            </div>
            <div className="containerTime leftTime">
              <div className="contentTime widget_feature">
                <h2>2013</h2>
                <p>
                  On 16th November 2013, the ACMS released the First Directory
                  of Matriculation Schools which comprises the photographs of
                  Correspondents and Principals with the details of Schools
                  Address, School Phone Number and Email-Id.
                </p>
                <p>
                  It is a Milestone in the history of the Academic Council
                  within the 3 years of service, The ACMS has established its
                  Motto
                </p>
              </div>
            </div>
            <div className="containerTime rightTime">
              <div className="contentTime widget_feature">
                <h2>2016</h2>
                <p>In 2016, the new office bearers have taken the charge.</p>
                <ul>
                  <li>
                    <i className="fa fa-user" />
                    <span>Convenor :</span> RRev.Fr.Maria Joseph, Principal, St.
                    Joseph MHSS
                  </li>
                  <li>
                    <i className="fa fa-user" />
                    <span>Treasurer :</span> Mt.P.V.Antony, Principal, Suguna
                    RIP V MHSS
                  </li>
                </ul>
              </div>
            </div>
            <div className="containerTime leftTime">
              <div className="contentTime widget_feature">
                <h2>2018</h2>
                <p>
                  In 2018, the name of Academic Council of Matriculation Schools
                  has been changed to Academic Committee of Matriculation
                  Schools Society due to official Registration of our
                  organisation under Tamil Nadu Society Act.
                </p>
              </div>
            </div>
            <div className="containerTime rightTime">
              <div className="contentTime widget_feature">
                <h2>2022</h2>
                <p>In 2022, the new office bearers have taken the charge.</p>
                <ul>
                  <li>
                    <i className="fa fa-user" />
                    <span>President :</span> Fr.Dr.P.Arokia Thadayus
                  </li>
                  <li>
                    <i className="fa fa-user" />
                    <span>Vice-Presidents :</span>
                    <ul className="underLI">
                      <li>Mrs.Vasanthi Balraj</li>
                      <li>Mrs.Sabural Banu Ibrahim</li>
                      <li>Mr.G.Antonyraj</li>
                    </ul>
                  </li>
                  <li>
                    <i className="fa fa-user" />
                    <span>Secretary :</span> Mr.N.Saravana Kumar
                  </li>
                  <li>
                    <i className="fa fa-user" />
                    <span>Joint Secretaries :</span>
                    <ul className="underLI">
                      <li>Mrs.A.SAnbahavalli</li>
                      <li>Mrs.P.S.Sripriya</li>
                    </ul>
                  </li>
                  <li>
                    <i className="fa fa-user" />
                    <span>Treasurer :</span> Mr.P.Manimaran
                  </li>
                  <li>
                    <i className="fa fa-user" />
                    <span>Joint Treasurer :</span> Mr.H.K.Santhosh Kumar
                  </li>
                  <li>
                    <i className="fa fa-user" />
                    <span>Auditing Committee :</span>
                    <ul className="underLI">
                      <li>Mr.P.V.Antony</li>
                      <li>Mrs.Indra Ramesh</li>
                      <li>Mr.M.Venkatachalam</li>
                      <li>Mr.J.T.Jeba Selvin</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TimeLine;
