import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const AboutV2 = (sectionclass) => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  let SectionClass = sectionclass ? sectionclass : "";

  return (
    <div className={"about-area pd-top-120 go-top " + SectionClass}>
      <div className="container-fluid paD-45">
        <div className="about-area-inner">
          <div className="row">
            <div className="col-lg-6 col-md-10">
              <div
                className="about-thumb-wrap after-shape"
                style={{
                  backgroundImage:
                    'url("' + publicUrl + 'assets/img/message/presidents.jpg")',
                }}
              ></div>
            </div>
            <div className="col-lg-6">
              <div className="about-inner-wrap">
                <div className="section-title mb-0">
                  <h6 className="sub-title right-line">Presidents Message</h6>
                  <h4 className="title">Fr. P. Arokia Thadayus, Convener.</h4>
                  {/* <span>
                    <i className="fa fa-phone" /> +91 8148657070
                  </span> */}
                  <p className="content">
                    I am honoured to be the first member and a worker of the
                    Academic Committee of Matriculation Schools. The Committee
                    serves as a valuable forum that benefits both schools and
                    department alike. It is not just a one man show, but rather
                    a collaborative effort involving 29 Principals as
                    coordinators, we act as a bridge between the department and
                    the schools, ensuring effective communication and
                    co-operation. Our joint returns alms to enhance the quality
                    of education and create a supportive environment for
                    students to thrive. Together, we are committed in making a
                    positive impact on the education system and shaping the
                    future of our students. Whenever the department needs
                    support, we provide it. Furthermore, When the department
                    requests data collection, it is through our co-ordinators
                    that the work is being done. Similarly, whenever any
                    programme is requested, the co-ordinators along with the
                    Principals, we work together to organise it. So, it is not a
                    committee, it is a family. In the same way, the department
                    organise so many programmes such as in-service trainings,
                    training programmes and the award functions.
                  </p>
                  <p>
                    The main objective of ACMS is to make things easier for the
                    department and also for the schools. It is also a body of
                    para-Government and a help to the students. So, I am proud
                    to be the convener in this esteemed Committee and to look
                    forward in making a meaningful contribution. Let my service
                    be with the ACMS.
                  </p>
                  {/* <div className="row">
                    <div className="col-sm-6">
                      <ul className="single-list-wrap">
                        <li className="single-list-inner style-check-box">
                          <i className="fa fa-check" /> Metus interdum metus
                        </li>
                        <li className="single-list-inner style-check-box">
                          <i className="fa fa-check" /> Ligula cur maecenas
                        </li>
                        <li className="single-list-inner style-check-box">
                          <i className="fa fa-check" /> Fringilla nulla
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul className="single-list-wrap">
                        <li className="single-list-inner style-check-box">
                          <i className="fa fa-check" /> Metus interdum metus
                        </li>
                        <li className="single-list-inner style-check-box">
                          <i className="fa fa-check" /> Ligula cur maecenas
                        </li>
                        <li className="single-list-inner style-check-box">
                          <i className="fa fa-check" /> Fringilla nulla
                        </li>
                      </ul>
                    </div>
                  </div>
                  <Link className="btn btn-border-black " to="/about">
                    Read More
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutV2;
