import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Footer from "./global-components/footer";
import ExeComList from "./section-components/exe-com-list";

const ExeCommittee = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Executive Committee" />
      <ExeComList />
      {/* <Team /> */}

      <Footer />
    </div>
  );
};

export default ExeCommittee;
