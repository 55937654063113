import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Team from "./section-components/team-v3";
import Footer from "./global-components/footer";
import ExamTTable from "./section-components/exam-t-table";

const PublicExamTimeTable = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Board Exam" />
      <ExamTTable />
      {/* <Team /> */}

      <Footer />
    </div>
  );
};

export default PublicExamTimeTable;
