import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Footer from "./global-components/footer";
import DirectoryExeComList from "./section-components/directory-exe-com-list";

const Directory = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Directory" />
      <DirectoryExeComList />
      <Footer />
    </div>
  );
};

export default Directory;
