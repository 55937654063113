import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import { client } from "../../client";

const GOrder = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  const [timeTables, setTimeTables] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "gorder"]{
        title,
        slug,
        file{
             asset->{
                  _id,
                  url
                }
              },
    }`
      )
      .then((data) => setTimeTables(data))
      .catch(console.error);
  }, []);
  if (!timeTables) {
    return <h2></h2>;
  }
  return (
    <>
      <div className="intro-area intro-area--top">
        <div className="container">
          {timeTables &&
            timeTables.map((item, index) => (
              <div
                className="intro-area-inner intro-home-1 bg-white"
                key={index}
              >
                <div className="section-title text-center mt-40">
                  <h6 className="sub-title double-line">Goverment Orders</h6>
                  <h2 className="title h2-reSize">{item.title}</h2>
                </div>
                <div className="pdf-container">
                  <div className="pdf-emb mobileHd">
                    <embed src={item.file.asset.url} type="application/pdf" />
                  </div>
                  <div className="pdf-emb mobileDis">
                    <a
                      className="pdfBtn btn btn-base b-animate-3 mr-sm-3 mr-2"
                      href={item.file.asset.url}
                      target="_blank"
                    >
                      <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                      View PDF{" "}
                    </a>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default GOrder;
