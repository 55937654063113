import React, { useEffect, useState } from "react";
import { client } from "../../client";
import DirectorySubList from "./directory-sub-list";

const DirectoryExeComList = () => {
  const [ecommt, setEcommt] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "ecommittee"] | order(name asc) {
          _id,
          name,
          cname,
          pname,
          pphone,
          code,
          sphone,
          email, 
          _rev,       
    }`
      )
      .then((data) => setEcommt(data))
      .catch(console.error);
  }, []);
  if (!ecommt) {
    return <h2></h2>;
  }
  return (
    <>
      <div className="course-single-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="faq-w">
              <div className="course-details-content">
                <div id="accordion" className="accordion-area mt-12">
                  {ecommt &&
                    ecommt.map((item, index) => (
                      // <div className="bgSide">
                      <div
                        className="card single-faq-inner style-no-border cardDEX"
                        key={index}
                      >
                        <div
                          className="card-header collapsed"
                          id="ff-one"
                          data-toggle="collapse"
                          data-target={`#ACMS${item._rev}`}
                          aria-expanded="false"
                          aria-controls="f-one"
                        >
                          <h5 className="mb-0">
                            <button className="btn-link">
                              {item.name}{" "}
                              {/* <span className="smallSP">
                                - UDISE CODE : {item.code}
                              </span> */}
                              <i className="fa fa-eye" />
                            </button>
                          </h5>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="eCom widget_feature ">
                                <ul>
                                  <li>
                                    <span>
                                      <i className="fa fa-user" />
                                      CORRESPONDENT NAME :
                                    </span>{" "}
                                    {item.cname}
                                  </li>
                                  <li>
                                    <span>
                                      <i className="fa fa-user" />
                                      PRINCIPAL NAME :
                                    </span>{" "}
                                    {item.pname}
                                  </li>
                                  <li>
                                    <span>
                                      {" "}
                                      <i className="fa fa-envelope" />
                                      SCHOOL EMAIL ID :
                                    </span>{" "}
                                    {item.email}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="eCom extUl widget_feature ">
                                <ul>
                                  <li>
                                    <span>
                                      {" "}
                                      <i className="fa fa-phone-square" />
                                      PRINCIPAL PHONE NUMBER :
                                    </span>{" "}
                                    {item.pphone}
                                  </li>
                                  <li>
                                    <span>
                                      <i className="fa fa-phone-square" />
                                      SCHOOL PHONE NUMBER :
                                    </span>{" "}
                                    {item.sphone}
                                  </li>
                                  <li>
                                    <span>
                                      <i className="fa fa-barcode" />
                                      UDISE CODE :
                                    </span>{" "}
                                    {item.code}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id={`ACMS${item._rev}`}
                          className="collapse"
                          aria-labelledby="ff-one"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <DirectorySubList ecid={item._id} />
                          </div>
                        </div>
                      </div>
                      // </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DirectoryExeComList;
