import React, { useEffect, useState } from "react";
import { client } from "../../client";

const ExeComList = () => {
  const [ecommt, setEcommt] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "ecommittee"]{
          _id,
          name,
          cname,
          pname,
          pphone,
          sphone,
          email,        
    }`
      )
      .then((data) => setEcommt(data))
      .catch(console.error);
  }, []);
  if (!ecommt) {
    return <h2></h2>;
  }
  return (
    <>
      <div className="course-single-area pd-top-120 pt-mob-0 pd-bottom-90">
        <div className="container">
          <div className="row">
            {ecommt &&
              ecommt.map((item, ind) => (
                <div className="col-lg-6" key={ind}>
                  <div className="td-sidebar hoverDiv">
                    <div className="widget widget_feature">
                      <h2 className="widget-title">{item.name}</h2>
                      <ul>
                        <li>
                          <span>
                            <i className="fa fa-user" />
                            CORRESPONDENT NAME :
                          </span>{" "}
                          {item.cname}
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-user" />
                            PRINCIPAL NAME :
                          </span>{" "}
                          {item.pname}
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-phone" /> PRINCIPAL PHONE NUMBER
                            :
                          </span>{" "}
                          {item.pphone}
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-mobile" />
                            SCHOOL PHONE NUMBER :
                          </span>{" "}
                          {item.pphone}
                        </li>
                        <li>
                          <span>
                            {" "}
                            <i className="fa fa-envelope" />
                            SCHOOL EMAIL ID :
                          </span>{" "}
                          {item.email}
                        </li>
                      </ul>
                      {/* <div className="price-wrap text-center">
                        <h5>
                          Price:<span>$54.00</span>
                        </h5>
                        <a
                          className="btn btn-base btn-radius"
                          to="/course-details"
                        >
                          ENROLL COURSE
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExeComList;
