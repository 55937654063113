import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeV1 from "./components/home-v1";
import HomeV2 from "./components/home-v2";
import HomeV3 from "./components/home-v3";
import Course from "./components/course";
import CourseDetails from "./components/course-details";
import About from "./components/about";
import OfficeBearers from "./components/officeBearers";
import Event from "./components/event";
import EventDetails from "./components/event-details";
import Instructor from "./components/instructor";
import InstructorDetails from "./components/instructor-details";
import Pricing from "./components/pricing";
import Gallery from "./components/gallery";
import SignIn from "./components/sign-in";
import SignUp from "./components/sign-up";
import Contact from "./components/contact";
import Blog from "./components/blog";
import BlogDetails from "./components/blog-details";
import PublicExamTimeTable from "./components/public-exam-time-table";
import ExeCommittee from "./components/executive-committee";
import Directory from "./components/directory";
import ProceduresForm from "./components/proceduresForm";
import GalleryDetailPage from "./components/gallerydetail";
import GovermentOrder from "./components/govermentOrder";

class Root extends Component {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={HomeV1} />
            <Route path="/home-v2" component={HomeV2} />
            <Route path="/home-v3" component={HomeV3} />
            <Route path="/course" component={Course} />
            <Route path="/course-details" component={CourseDetails} />
            <Route path="/about" component={About} />
            <Route path="/office-bearers" component={OfficeBearers} />
            <Route path="/board-exam" component={PublicExamTimeTable} />
            <Route path="/procedures-forms" component={ProceduresForm} />
            <Route path="/goverment-orders" component={GovermentOrder} />
            <Route path="/executive-committee" component={ExeCommittee} />
            <Route path="/directory" component={Directory} />
            <Route path="/event" component={Event} />
            <Route path="/event-details" component={EventDetails} />
            <Route path="/instructor" component={Instructor} />
            <Route path="/instructor-details" component={InstructorDetails} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/gallery-detail/:slug" component={GalleryDetailPage} />
            <Route path="/sign-in" component={SignIn} />
            <Route path="/sign-up" component={SignUp} />
            <Route path="/contact" component={Contact} />
            <Route path="/blog" component={Blog} />
            <Route path="/blog-details" component={BlogDetails} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("edumint"));
