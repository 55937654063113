import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Banner extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="banner-area banner-area-1 bg-gray go-top bgImgBanner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-8 order-lg-12 align-self-center">
              <div className="thumb b-animate-thumb">
                <img src={publicUrl + "assets/img/banner/1.png"} alt="img" />
              </div>
            </div>
            <div className="col-lg-7 order-lg-1 align-self-center">
              <div className="banner-inner text-center text-lg-left mt-5 mt-lg-0">
                <h6 className="b-animate-1 sub-title">SERVICE IN UNISON</h6>
                <h2 className="b-animate-2 title">
                  The goal of True Education Intelligence and Character
                </h2>
                <p className="b-animate-2">
                  <span className="testimonial-quote">
                    <i className="fa fa-quote-left"></i>
                  </span>
                  True education should empower students to make informed
                  decisions, exhibit leadership qualities, and lead fulfilling
                  lives while respecting the rights and well-being of others".
                </p>
                {/* <Link
                  className="btn btn-base b-animate-3 mr-sm-3 mr-2"
                  to="/contact"
                >
                  Get A Quote
                </Link>
                <Link
                  className="btn btn-border-black b-animate-3"
                  to="/blog-details"
                >
                  Read More
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
