import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Footer from "./global-components/footer";
import ProForms from "./section-components/pro-forms";

const ProceduresForm = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Procedures Forms" />
      <ProForms />
      {/* <Team /> */}

      <Footer />
    </div>
  );
};

export default ProceduresForm;
