import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer_v1 extends Component {
  componentDidMount() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <footer className="footer-area bg-gray">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="widget widget_contact">
                  <h4 className="widget-title">Contact Us</h4>
                  <ul className="details">
                    <li>
                      <i className="fa fa-map-marker" /> St. Joseph Matric Hr.
                      Sec School Ondipudur, Coimbatore-641033, TamilNadu.
                    </li>
                    <li>
                      <i className="fa fa-envelope" /> acmatricschools@gmail.com
                    </li>
                    <li>
                      <i className="fa fa-phone" /> +91 9994622712
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="widget widget_nav_menu">
                  <h4 className="widget-title">Quick Links</h4>
                  <ul className="go-top">
                    <li>
                      <Link to="#">Governing Body</Link>
                    </li>
                    <li>
                      <Link to="#">Gallery</Link>
                    </li>
                    <li>
                      <Link to="#">Directory</Link>
                    </li>
                    <li>
                      <Link to="#">Government Orders</Link>
                    </li>
                    <li>
                      <Link to="#">Procedures & Forms</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 go-top">
                <div className="widget widget_blog_list">
                  <h4 className="widget-title">Location</h4>
                  <div className="footerIframe">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.454423295142!2d77.04274277451998!3d11.004491954946635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8571a9c31ce7d%3A0xc5cc135a182aa90!2sSt.%20Joseph&#39;s%20Matriculation%20Higher%20Secondary%20School!5e0!3m2!1sen!2sin!4v1706168205482!5m2!1sen!2sin"
                      width="100%"
                      height="200"
                    ></iframe>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6">
                <div className="widget widget_contact">
                  <h4 className="widget-title">Twitter Feed</h4>
                  <ul className="details">
                    <li>
                      <i className="fa fa-twitter" />
                      Simply dummy brand <a href="#">https//tweets/c3l.com</a>
                      <div className="time">9 Hours ago</div>
                    </li>
                    <li>
                      <i className="fa fa-twitter" />
                      Simply dummy brand <a href="#">https//tweets/c7l.com</a>
                      <div className="time">9 Hours ago</div>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 align-self-center">
                <a href="index.html">
                  <img
                    width="80px"
                    src={publicUrl + "assets/img/logo.png"}
                    alt="img"
                  />
                </a>
              </div>
              <div className="col-lg-4  col-md-6 order-lg-12 text-md-right align-self-center">
                <ul className="social-media mt-md-0 mt-3">
                  <li>
                    <a className="facebook" href="#">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a className="twitter" href="#">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a className="instagram" href="#">
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a className="youtube" href="#">
                      <i className="fa fa-youtube" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 order-lg-8 text-lg-center align-self-center mt-lg-0 mt-3">
                <p>copyright 2024 by acmsscbe.com</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer_v1;
