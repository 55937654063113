import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { client } from "../../client";

const GalleryDetails = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [postgallery, setgalleryData] = useState(null);
  const { slug } = useParams();
  useEffect(() => {
    client
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          mainImage{
               asset->{
                    _id,
                    url
                  }
                },
                imagesGallery[]{
                  asset->{
                    _id,
                    url
                  }
                },
       }`,
        { slug }
      )
      .then((data) => setgalleryData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!postgallery) return <div></div>;

  return (
    <div className="team-area pd-top-40 pd-bottom-90 go-top">
      <div className="container">
        <h2 className="text-center pd-bottom-40">{postgallery.title}</h2>
        <div className="row justify-content-center col-size-full">
          {postgallery.imagesGallery.map((item, index) => (
            <div className="col-Size" key={index}>
              <div className="single-gallery-inner">
                <div className="thumb">
                  <img src={item.asset.url} alt="img" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GalleryDetails;
